<template>
    <div>
        <div class="choose" ref="choose" >
            <div class="content" ref="content" @mousemove="handleMove" @mouseout="handleOut">
                <img :src="imgsrc"  />
                <div class="shadow" ref="shadow"></div>
            </div>
            <ul id="listshow" >
                <li v-for="(item,i) in list" :key="i" :class=" index==i ?'selectedimg':''"
                 @click="imgclick(item.urlItem,i)">
                    <img :src="item.urlItem" alt="">
                </li>
            </ul>
        </div>
        <div class="larger" ref="larger">
            <img :src="imgsrc" ref="big" />
        </div>
    </div>
</template>
<script>
 import deviceImg from "../assets/home/device.png";
import smartImg from "../assets/home/smart.png";
import alarmImg from "../assets/home/alarm.png";
import systemImg from "../assets/home/system.png";
export default {
    data() {
        return {
            list:[{urlItem:deviceImg},{urlItem:smartImg},{urlItem:deviceImg},{urlItem:deviceImg},{urlItem:deviceImg},{urlItem:alarmImg},{urlItem:systemImg},],
           imgsrc:'',
           index:0
        };
    },
    mounted(){
        this.imgsrc = this.list[0].urlItem;
    },
    methods: {
        imgclick(url,i){
            this.imgsrc = url;
            this.index = i
        },
        // 获取元素到文档区域的坐标 
        getPosition: function(element){ 
            var dc = document, 
            rec = element.getBoundingClientRect(), 
            x = rec.left, // 获取元素相对浏览器视窗window的左、上坐标 
            y = rec.top; 
            // 与html或body元素的滚动距离相加就是元素相对于文档区域document的坐标位置 
            x += dc.documentElement.scrollLeft || dc.body.scrollLeft; 
            y += dc.documentElement.scrollTop || dc.body.scrollTop; 
            return { 
                left: x, 
                top: y 
            }; 
        },
        handleMove(evt) {
            var larger = this.$refs.larger;
            var shadow = this.$refs.shadow;
            var big = this.$refs.big;
            var pos = this.getPosition(this.$refs.choose);
            var shadowRect = shadow.getBoundingClientRect();
            var bigRect = big.getBoundingClientRect();
            var contentRect = this.$refs.content.getBoundingClientRect();
            var maxX = contentRect.width - shadowRect.width;
            var maxY = contentRect.height - shadowRect.height;
 
            var X = evt.pageX - pos.left - shadowRect.width / 2;
            var Y = evt.pageY - pos.top - shadowRect.height / 2;
 
            if (X <= 0) {
                X = 0;
            }
            if (X >= maxX) {
                X = maxX;
            }
            if (Y <= 0) {
                Y = 0;
            }
            if (Y >= maxY) {
                Y = maxY;
            }
            // 防止遮罩层粘滞，跟随鼠标一起滑出大图位置
            var bigX = X * bigRect.width / contentRect.width;
            var bigY = Y * bigRect.height / contentRect.height;
            //  bigX / bigW = X / contentW,主图和遮罩层之间存在两倍关系，放大图和原图之间也有两倍关系
            shadow.style.left = X + "px";
            shadow.style.top = Y + "px";
 
            // console.log(X, Y, bigX, bigY);
 
            big.style.left = -bigX + "px";
            big.style.top = -bigY + "px";
 
            larger.style.display = "block";
            shadow.style.display = "block";
        },
        handleOut(evt) {
            var larger = this.$refs.larger;
            var shadow = this.$refs.shadow;
            larger.style.display = "none";
            shadow.style.top = "-1000px";
        }
    }
};
</script>
<style scoped>
.choose {
    width: 400px;
    height: 600px;
    float: left;
    margin: 10px 0 0 10px;
}
 
.content {
    width: 400px;
    height: 400px;
    position: relative;
}
 
.content img {
    width: 400px;
    height: 400px;
}
#listshow {
    width: 400px;
    height: 100px;
    margin-top: 20px;
    display: flex;
}
 
#listshow li {
    width: 98px;
    height: 100px;
    /* float: left; */
    border: 1px solid #666;
    margin: 20px;
    list-style: none;
}
 
#listshow li img {
    width: 98px;
    height: 100px;
}
 
#listshow .selectedimg {
    border-color: brown;
}
 
.larger {
    width: 400px;
    height: 400px;
    position: absolute;
    top: 0px;
    left: 460px;
    float: left;
    overflow: hidden;
    display: none;
}
 
.larger img {
    width: 800px;
    height: 800px;
    position: absolute;
    left: 0;
    top: 0;
    border-color: brown;
}
 
.shadow {
    width: 200px;
    height: 200px;
    background-color: rgba(145, 200, 200, 0.4);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    display: none;
}
</style>